
import './assets/main.css' // CSS styling for the user interface
import { defineComponent } from 'vue'
import { convert } from './services/convertService'

export default defineComponent({
  data () {
    return {
      amount: 0, // The amount to convert
      fromCurrency: 'USD', // The base currency
      toCurrency: 'EUR', // The target currency
      currencies: ['USD', 'EUR', 'GBP', 'JPY'], // Available currencies
      convertedAmount: null as number | null, // The converted amount (initially set to null)
      isConverting: false, // Flag to control the conversion process
      errorMessage: '' // Error message on conversion error
    }
  },
  methods: {
    /**
    * Perform the currency conversion.
    * If the input amount is valid, it calls the `convert` function from the service to get the converted amount.
    * If an error occurs during the conversion, it sets an error message.
    */
    async convert () {
      if (!this.isInputValid()) {
        this.errorMessage = 'Please enter a valid amount.'
        return
      }

      this.isConverting = true
      this.errorMessage = ''
      try {
        this.convertedAmount = await convert(this.amount, this.fromCurrency, this.toCurrency)
      } catch (error) {
        // console.error(error)
        this.errorMessage = 'Failed to convert currencies.'
      }
      this.isConverting = false
    },
    /**
     * Check if the input amount is valid
     */
    isInputValid () {
      return Number.isFinite(this.amount) && this.amount >= 0
    }
  }
})

